









import Vue from "vue";

import AppBar from "@/components/AppBar.vue";

export default Vue.extend({
  name: "App",
  components: { AppBar },
});
