import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: () => import("../views/Calendar.vue"),
  },
  {
    path: "/settings/details",
    name: "SettingsDetails",
    component: () => import("../views/SettingsPraxisDetails.vue"),
  },
  {
    path: "/settings/employees",
    name: "EmployeeManagement",
    component: () => import("../views/SettingsEmployeeManagement.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Registration.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && to.name !== "Register" && !store.getters.getToken)
    next({ name: "Login" });
  else next();
});

export default router;
