












































































import Vue from "vue";
import AppBarLogin from "./AppBarLogin.vue";
import AppBarNotifications from "./AppBarNotifications.vue";

export default Vue.extend({
  components: {
    AppBarLogin,
    AppBarNotifications,
  },
  data() {
    return {
      drawer: true,
    };
  },
  computed: {
    isSignedIn(): boolean {
      return this.$store.getters.getToken ? true : false;
    },
  },
});
