












































import Vue from "vue";
import VisitCard from "@/components/VisitCard.vue";
import { Doctor, Appointment } from "@/types/Types";

export default Vue.extend({
  name: "Home",
  components: { VisitCard },
  data: () => ({
    appointments: [] as Appointment[],
    doctors: [] as Doctor[],
    intervalId: 0,
  }),
  mounted() {
    this.updateAppointments();
    this.updateDoctors();
    this.intervalId = setInterval(() => {
      this.updateAppointments();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  computed: {
    filteredAppointments() {
      let array = JSON.parse(JSON.stringify(this.appointments)).map(
        (appointment: Appointment) => {
          let temp = appointment.startDate.split("T");
          let dayArray = temp[0].split(".");
          appointment.convertedStartDate = new Date(
            parseInt(dayArray[2]),
            parseInt(dayArray[1]) - 1,
            parseInt(dayArray[0]),
            parseInt(temp[1].split(":")[0]),
            parseInt(temp[1].split(":")[1])
          );
          let doctor = this.doctors.find((d) => d.drId == appointment.drId);
          appointment.doctorName = `${doctor?.firstName} ${doctor?.lastName}`;
          return appointment;
        }
      );

      let sorted = array.sort((a: Appointment, b: Appointment) => {
        return (
          new Date(a.convertedStartDate).getTime() -
          new Date(b.convertedStartDate).getTime()
        );
      });

      let filtered = sorted.filter((item: Appointment) => {
        return new Date(item.convertedStartDate) > new Date();
      });
      return filtered.slice(0, 4);
    },
  },
  methods: {
    updateAppointments() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/appointment",
        requestOptions
      )
        .then((response) => response.json())
        .then((appointments) => (this.appointments = appointments))
        .catch((error) => console.log("error", error));
    },
    updateDoctors() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/drs",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => (this.doctors = result))
        .catch((error) => console.log("error", error));
    },
  },
});
