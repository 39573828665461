

















































































import Vue from "vue";
export default Vue.extend({
  name: "AppBarNotifications",
  data() {
    return {
      dialogVisible: false,
      openAppointmentRequests: [],
      intervalId: 0,
    };
  },
  mounted() {
    this.loadAppointmentRequests();
    this.intervalId = setInterval(() => {
      this.loadAppointmentRequests();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    loadAppointmentRequests() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/nurse/request/all",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          this.openAppointmentRequests = data;
        })
        .then((data) => console.log(data));
    },
    approveAppointmentRequest(id: number) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
      };

      fetch(
        `https://mdic-backend.herokuapp.com/api/v1/clinic/nurse/confirm/${id}`,
        requestOptions
      )
        .then((response) => response.text())
        .then(() => this.loadAppointmentRequests())
        .catch((error) => console.log("error", error));
    },
    declineAppointmentRequest(id: number) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
      };

      fetch(
        `https://mdic-backend.herokuapp.com/api/v1/clinic/nurse/cancel/${id}`,
        requestOptions
      )
        .then((response) => response.text())
        .then(() => this.loadAppointmentRequests())
        .catch((error) => console.log("error", error));
      this.loadAppointmentRequests();
    },
  },

  computed: {},
});
