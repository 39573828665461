






import Vue from "vue";
export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    isSignedIn(): boolean {
      return this.$store.getters.getToken ? true : false;
    },
  },
  methods: {
    login() {
      this.$router.push("/login");
    },
  },
});
