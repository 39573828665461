






















import Vue from "vue";
export default Vue.extend({
  props: ["date", "patientName", "doctorName"],
  computed: {
    formattedDate() {
      return new Date(this.date).toLocaleString("de-DE", {
        day: "numeric",
        month: "short",
        weekday: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
});
